<template>
    <div>

      <!--篩選區塊-->
      <el-row>
        <div style="float: right;">
          <!--替換語系-->
          <el-select v-model="listQuery.serviceId" clearable size="small" v-bind:placeholder="$t('AllOperator')" class="filter-item-mid" v-if="serviceList.length>0">
            <el-option v-for="item in serviceList" :key="item.id" :label="item.name + ' (' + item.code + ')'" :value="item.id" />
          </el-select>
          <el-select v-model="listQuery.enabled" v-bind:placeholder="$t('EnabledStatus')" size="small" class="filter-item">
            <el-option v-bind:label="$t('Enable')" :value="true" />
            <el-option v-bind:label="$t('Disable')" :value="false" />
          </el-select>
          <el-input size="small" v-model="listQuery.name" v-bind:placeholder="$t('Account')+$t('Name')" class="filter-item" clearable style="width: 180px;"/>
        </div>
      </el-row>
      <el-row style="text-align: left; font-size: .8em; padding-right: 10px; color: dimgrey; padding-left: 10px; margin-bottom: 5px;" >
        <span >產製時間: {{ reportDate }} </span>
      </el-row>

      <!-- table -->
      <el-table
          v-loading="listLoading"
          :data="list" style="width: 100%;"
          :cell-style="{ padding: '0', height: '40px' }"
          @sort-change="tableSortChange">

        <el-table-column align="center" prop="account" :show-overflow-tooltip="true"
                         :render-header="renderAccountHeaderMethods"   :label="$t('Account')" sortable>
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" :content="scope.row.account" placement="top-start">
              <span>{{scope.row.account.replace(scope.row.account.substr(6,4),"****")}}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="name" :show-overflow-tooltip="true"
                         :render-header="renderNameHeaderMethods"  :label="$t('Name')" sortable>
          <template slot-scope="scope">
            <el-tooltip v-if="scope.row.name != null" class="item" effect="dark" :content="scope.row.name" placement="top-start">
            <span v-if="scope.row.name != null">{{hideName(scope.row.name)}}</span>
            </el-tooltip>
            <el-tag v-else type="danger">未完成註冊</el-tag>
          </template>
        </el-table-column>

        <el-table-column align="center" prop="email" :show-overflow-tooltip="true"
                         :render-header="renderEmailHeaderMethods"  :label="$t('Email')">
          <template slot-scope="scope">
            <el-tooltip v-if="scope.row.email != null" class="item" effect="dark" :content="scope.row.email" placement="top-start">
              <span v-if="scope.row.email != null">{{hideEmail(scope.row.email)}}</span>
            </el-tooltip>

          </template>
        </el-table-column>
        <el-table-column align="center" prop="gender" :label="$t('Gender')"/>
        <el-table-column align="center" prop="serviceInfo.name"  :label="$t('Company')" sortable v-if="serviceList.length>0"/>
        <el-table-column prop="enabled" :label="$t('EnabledStatus')" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.enabled" type="success">{{$t('Enable')}}</el-tag>
            <el-tag v-else type="danger">{{$t('Disable')}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="bindCard" :label="$t('BindCard')">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.bindCard" type="success" style="cursor: pointer;" @click.native="showBindCard(scope.row)">已綁定</el-tag>
            <el-tag v-else type="danger">未綁定</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="loggedIn" :label="$t('Login')+$t('Date')" sortable :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div :title="scope.row.loggedIn">{{ parseDateTime(scope.row.loggedIn) }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="updatedOn" :label="$t('Update')+$t('Date')" sortable :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div :title="scope.row.updatedOn">{{ parseDateTime(scope.row.updatedOn) }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="createdOn" :label="$t('Create')+$t('Date')" sortable :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div :title="scope.row.createdOn">{{ parseDateTime(scope.row.createdOn) }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" :label="$t('Settings')" width="80px">
          <template slot-scope="scope">
            <el-button type="success" size="mini" icon="el-icon-setting" @click="showSetting(scope.row)" />
          </template>
        </el-table-column>
        <el-table-column align="center" :label="$t('Delete')" width="65px">
          <template slot-scope="scope">
            <el-button type="danger" size="mini" icon="el-icon-delete"
                       @click="handleDelete(scope.$index, scope.row)" />
          </template>
        </el-table-column>
      </el-table>
      <!--分頁組件-->
      <pagination v-show="total > 0" :total="total" :current.sync="paginationSelectedPage" :size.sync="listQuery.size"
                  @pagination="getList" />

      <!--設定Dialog-->
      <el-dialog :title="$t('MemberSetting')" width="600"  :visible.sync="settingDialog.show" >
        <template>
        <el-form ref="dataForm" :rules="rules" :model="updateQuery" size="small" style="margin-top: 10px;">
          <el-row :gutter="10">
            <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
              <el-form-item prop="account" :label="$t('MemberAccount')" label-width="150px">
                <span>{{updateQuery.account}}</span>
              </el-form-item>
            </el-col>
            <el-col :xs="22" :sm="22" :md="11" :lg="10" :xl="11">
              <el-form-item prop="name" :label="$t('MemberName')" label-width="150px">
                <span>{{updateQuery.name}}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :xs="22" :sm="22" :md="11" :lg="10" :xl="11">
              <el-form-item prop="name" :label="$t('Operator')" label-width="150px">
                <span>{{updateQuery.service}}</span>
              </el-form-item>
            </el-col>
            <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
              <el-form-item prop="enabled" v-bind:label="$t('EnableStatus')" label-width="150px">
                <el-select v-model="updateQuery.enabled" size="small" class="filter-item" style="width: 200px">
                  <el-option v-bind:label="$t('Enable')" :value="true" />
                  <el-option v-bind:label="$t('Disable')" :value="false" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :xs="22" >
              <div style="margin-bottom: 20px;">{{$t('MemberSettingNote')}}</div>
            </el-col>
          </el-row>
        </el-form>
        <el-button style="margin-bottom: 0px; margin-top: 10px;" size="median" type="primary" @click="updateAppUser()">{{$t('Save')}}</el-button>
        </template>
      </el-dialog>

      <!--綁卡Dialog-->
      <el-dialog :title="bindCardDialog.title" :visible.sync="bindCardDialog.show" width="75%" center :close-on-click-modal="false">
        <el-tabs v-model="activeTabPaneName" class="user-tabs" @tab-click="handleClick">

          <el-tab-pane label="支付列表" name="credit-card" style="padding: 0px 10px;"> <!--v-loading="chargingloading" -->
            <template>


<!--            <el-row>-->
<!--              <div v-loading="this.loading" element-loading-text="請稍後"> </div>-->
<!--            </el-row>-->

              <el-row>
                <el-card class="box-card">
                  <div slot="header" class="clearfix">
                    <span style="font-size: 1.0rem;font-weight: bold;">支付列表</span>
<!--                    <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>-->
                  </div>
                  <el-col>
                    <div style="margin-bottom: 20px; font-size: 14px; text-align: left;">[註] 使用顏色標註「當前支付選項」設定</div>
                  </el-col>
                  <el-table  :data="creditCardList" border  height="450" style="width: 100%;" :cell-style="{ padding: '0', height: '70px' }"
                             :default-sort = "{prop: 'update_time', order: 'descending'}"   :row-class-name="tableRowClassName"  >
                    <el-table-column  label="#" style="font-size: 1.0rem" type="index" sortable>
                      <template slot-scope="scope"><span>{{ scope.$index + 1 }}</span></template>
                    </el-table-column>
                    <el-table-column   align="center"  label="類型"   :show-overflow-tooltip="true">
                      <template slot-scope="props">
                        <span v-if="props.row.type == 'CreditCard'">
                          <span>信用卡</span>
                          <svg-icon
                              style="height:1.5em; width:2em;"
                              iconName='business-card'
                          />
                          ******{{ props.row.lastFour }}</span>
                        <span v-else>LinePay</span>
                      </template>
                    </el-table-column>
                    <el-table-column  style="font-size: 1.0rem" align="center"  label="到期日"   :show-overflow-tooltip="true">
                      <template slot-scope="props">
                        <span v-if="props.row.type == 'CreditCard'">{{ $moment(props.row.expiryDate).format('YYYY-MM') }}</span>
                        <span v-else>N/A</span>
                      </template>
                    </el-table-column>
                    <el-table-column  align="center"  label="發卡銀行"   :show-overflow-tooltip="true" >
                      <template slot-scope="props">
                        <span v-if="props.row.type == 'CreditCard'" style="margin-left: 10px">{{ props.row.issuerZhTW}}</span>
                        <span v-else>N/A</span>
                      </template>
                    </el-table-column>
                    <el-table-column  align="center" label="銀行代碼"   :show-overflow-tooltip="true" >
                      <template slot-scope="props">
                        <span v-if="props.row.type == 'CreditCard'" style="margin-left: 10px">{{ props.row.bankid}}</span>
                        <span v-else>N/A</span>
                      </template>
                    </el-table-column>
                    <el-table-column   sortable style="font-size: 1.0rem" align="center" prop="update_time" label="更新時間"   :show-overflow-tooltip="true" />
                  </el-table>
                </el-card>
              </el-row>
            </template>
          </el-tab-pane>
          <el-tab-pane label="雲端電子發票" name="einvoice" style="padding: 0px 10px;"> <!--v-loading="chargingloading" -->
            <template>
              <el-row :gutter="20">
                <!--<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 10px;">
                  <span class="data-label">發票通知信箱: </span>{{ formatInvoiceData(invoiceData.email) }}
                </el-col>-->
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 10px;">
                  <span class="data-label">手機條碼載具: </span>{{ formatInvoiceData(invoiceData.phoneCarrier) }}
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 10px;">
                  <span class="data-label">自然人憑證載具: </span>{{ formatInvoiceData(invoiceData.naturePersonCarrier) }}
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 10px;">
                  <span class="data-label">捐贈碼: </span>{{ formatInvoiceData(invoiceData.donationCarrier) }}
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 10px;">
                  <span class="data-label">雲端會員載具: </span>{{ formatInvoiceData(invoiceData.cloudMemberCarrier) }}
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 10px;">
                  <span class="data-label">報帳之公司名稱: </span>{{ formatInvoiceData(invoiceData.invoiceCompanyName) }}
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 10px;">
                  <span class="data-label">報帳之公司統編: </span>{{ formatInvoiceData(invoiceData.invoiceCompanyTaxid) }}
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 10px;">
                  <span class="data-label">更新日期: </span>{{ (invoiceData.update_time == undefined || invoiceData.update_time == "") ? formatInvoiceData(invoiceData.create_time) : invoiceData.update_time}}
                </el-col>
              </el-row>
            </template>
          </el-tab-pane>
        </el-tabs>

      </el-dialog>
    </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { mixins } from '@/views/common/mixins.js'
import {refreshToken, stopRefreshToken} from "@/utils/auth";


export default {
  name: 'User',
  components: {
    Pagination,
  },
  mixins: [mixins],
  data() {
    return {
      dialogWidth: 0,
      total: 0,
      list: [],
      listLoading: false,
      listQuery: {
        type: 'general',
        enabled: true,
        name: undefined,
        serviceId: undefined,
        page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
        size: 15
      },
      updateQuery: {
        id: undefined,
        account: undefined,
        name: undefined,
        service: undefined,
        serviceId: undefined,
        serviceCode: undefined,
        enabled: true,
      },
      rules: {
        enabled: [{ required: true, message: '(必填)', trigger: 'blur' }],
      },
      settingDialog:{
        title:this.$t('Member')+this.$t('Settings'),
        show:false,
      },
      bindCardDialog:{
        title:"支付資訊",
        show:false,
      },
      serviceList: [],
      creditCardList: [],
      paginationSelectedPage: 1, // 需配合Pagination元件與listQuery使用
      targetUrl:"",
      activeTabPaneName: "credit-card",
      invoiceData: {},
      reportDate: this.formatCurrentDateTime()

    };
  },
  watch: {
    paginationSelectedPage: {
      handler: function (newValue) {
        this.listQuery.page = newValue - 1
      }
    },
    'listQuery.enabled': function () {
      this.listQuery.page = 0
      this.resetPage(this.getList)
    },
    'listQuery.name': function () {
      this.listQuery.page = 0
      this.resetPage(this.getList)
    },
    'listQuery.serviceId': function () {
      this.listQuery.page = 0
      this.resetPage(this.getList)
    }
  },
  created() {
    console.log('@user currentAccount=', this.$store.state.currentAccount)
    this.dialogWidth = this.setDialogWidth()
    this.getServiceOptions();
    // await this.$store.dispatch("getUnitTable")
    this.resetPage(this.getList)
    //refreshToken();

  },
  beforeDestroy(){
    console.log("call beforeDestroy");
    //stopRefreshToken();
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.dialogWidth = this.setDialogWidth()
      })()
    }
  },
  methods: {
    tableRowClassName({row, rowIndex}) {

      if (row.preferred) {
        console.log('row='+row.id+",preferred="+row.preferred);
        return 'warning-row';
      }
      return '';
    },
    getList() {
      this.listLoading = true
      this.axios.get('/api/v1/user', { params: this.listQuery }).then(res => {
        console.log('user',res)
        this.list = res.data.content
        this.total = res.data.totalElements
        this.listLoading = false
      })
    },
    getServiceOptions() {
      this.listLoading = true
      this.axios.get('/api/v1/common/service/enabled').then(res => {
        this.serviceList = res.data
      })
    },
    updateAppUser() {
      console.log('updateAppUser');
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.axios.post('/api/v1/user', this.updateQuery).then(() => {
            this.$message({
              showClose: true,
              message: '修改成功',
              type: 'success'
            })
            this.settingDialog.show = false;
            this.getList();
          }).catch(err => {
            console.log('err:',err)
            this.$message({
              message: '修改失敗 '+err.data.msg,
              type: 'error'
            })
          })
        } else {
          this.$message({
            message: '輸入欄位格式不正確',
            type: 'error'
          })
        }
      })
    },
    handleAdd() {
      this.dialogSettingVisible = true
    },
    handleDelete(index, row) {
      /*替換語系
      this.$confirm('提示', {
          message: '確定刪除帳號 - ' + row.account + ' (' + row.name + ') ?',
          confirmButtonText: '確認',
          cancelButtonText: '取消', */
      this.$confirm(this.$t('Hint'), {
        message: this.$t('ConfirmDeleteAccount') + '-' + row.account + ' (' + row.serviceInfo.name + ') ?',
        confirmButtonText: this.$t('Confirm'),
        cancelButtonText:  this.$t('Cancel'),
        type: 'warning'
      }).then(() => {
        this.delAppUser(index, row.id)
      }).catch(() => {
        console.log('cancel delete account')
      })
    },
    async delAppUser(index, id) {
      this.axios.delete('/api/v1/user/' + id).then(() => {
        this.$delete(this.list, index);
        this.$message({
          showClose: true,
          //message: '刪除成功',//替換語系
          message: this.$t('Delete') + this.$t('Success'),
          type: 'success'
        })
      }).catch(err => {
        console.log('err:' + err)
        this.$message({
          //message: '刪除失敗',//替換語系
          message: this.$t('Delete') + this.$t('Fail'),
          type: 'error'
        })
      })
    },
    async showSetting(row) {
      this.settingDialog.show = true
      this.updateQuery.id = row.id;
      this.updateQuery.name = row.name;
      this.updateQuery.account = row.account;
      this.updateQuery.service = row.serviceInfo.name;
      this.updateQuery.enabled = row.enabled;
      this.updateQuery.serviceId = row.serviceInfo.id;
      this.updateQuery.serviceCode = row.serviceInfo.code;
    },
    async showBindCard(row) {
      this.bindCardDialog.show = true

      this.validDetailList = [];
      this.targetUrl = "/api/v1/user/"+row.id+"/cardInfo";

      //this.loading = true;
      this.axios.get(this.targetUrl).then(res => {
        this.creditCardList = res.data;
        console.log('creditCardList=',res);
      })
      this.getUserInvoice(row.id);

    },
    async getUserInvoice(id) {
      this.axios.get("/api/v1/user/"+id+"/invoice").then(res => {
        this.invoiceData = res.data;
      })
    },
    showRole(row) {
      console.log(row)
      this.dialogRoleVisible = true
      this.accountData = row
    },
    handleClick(tab, event) { // = (tab: TabsPaneContext, event: Event) => {
      console.log(tab, event)
    },
    dialogSettingOnClose() {
      this.dialogSettingVisible = false
      this.accountData = undefined
      this.resetPage(this.getList)
    },
    dialogRoleOnClose() {
      this.dialogRoleVisible = false
      this.accountData = undefined
      this.resetPage(this.getList)
    },
    formatInvoiceData( data ) {
      if (data == undefined || data == "" ) {
        return "未設定"
      }
      return data
    }
  }
}
</script>
<style>
/*.el-table th.el-table__cell>.cell{*/

/*display: flex;*/
/*}*/

.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
<style lang="scss" scoped>
.data-label {
  width: 120px; display: inline-block;
}
.data-amount{
  font-size: 1.2em;
  font-weight: bolder;
  color: #409eff;
}

.setting-data-label{
  font-size: 1.2em;
  font-weight: bold;
}


</style>
